import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as cs_columns from "config/columns";
import {
  ContentSetProps,
  ContentsetContextProps,
  ContentsetEntity,
  ContentsetPage,
  ContentsetPageWithError,
  FilterValues,
  LocaleCode,
  SelectOption,
  ViewMap,
  ViewString,
  ViewType,
} from "constants/types";
import { ContentsetContextProvider } from "contexts/contentset-context";
import { buildQueryUrl, useDidMountEffect, useQueryState } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import Head from "next/head";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "theme/theme";
import { Attribute_Option, Contentset } from "types";
import { ContentSetInterior } from "./interior";
import { useSlugPath } from "helpers/routing";
import { generateUrl } from "helpers/data/utils";
import classNames from "classnames";

const viewMap: ViewMap = {
  big_tiles: [12, 6, 6, 4, 3],
  small_tiles: [12, 6, 4, 3, 2],
  list: [12, 12, 12, 12, 12],
  fullscreen_button: [12, 12, 12, 6, 3],
};

type FilterQuery = {
  searchInput?: ContentsetContextProps["searchInput"];
  searchValue?: ContentsetContextProps["searchValue"];
  selectedOptions?: ContentsetContextProps["selectedOptions"];
};
type ColumnHidden = { hidden?: boolean; hiddenInTable?: boolean };

/**
 * Get raw column values
 * @param contentset used to determine correct columns based on layout and type
 * @returns Array of column definitions including additional data like visibility
 */
export const getRawColumns = (
  contentset: Contentset,
): ColumnDef<ContentsetEntity & { meta?: ColumnHidden }>[] => {
  return (
    cs_columns?.[
      `${contentset?.layout || "entity"}${
        contentset?.entity_type ? "_" + contentset?.entity_type : ""
      }`
    ] ||
    cs_columns?.[contentset?.layout || "entity"] ||
    cs_columns.entity
  );
};

/**
 * Get object with all hidden columns as keys with the value of false
 * @param contentset contentset to get correct columns
 * @param activeView current view to check if view is table
 * @returns object with all hidden columns as keys with the value of false
 */
export const getColumnVisibility = (
  contentset: Contentset,
  activeView: ViewString,
): { [key: string]: false } => {
  return getRawColumns(contentset).reduce(
    (acc: { [key: string]: false }, c) => {
      if (c.meta) {
        const meta = c.meta as ColumnHidden;
        if (
          meta?.hidden ||
          (activeView === "list_thin" && meta?.hiddenInTable)
        ) {
          // @ts-ignore - Why is accessorKey not available in ColumnDef?
          acc[c?.id || c?.accessorKey] = false;
        }
      }
      return acc;
    },
    {},
  );
};

export async function getSearchOptions(
  contentset: Contentset,
  locale: string,
  searchKey: string,
  filterValues: FilterValues,
): Promise<SelectOption[]> {
  let data: SelectOption[] | { error: Error } = [];

  try {
    const response = await fetch(`/api/contentset/search`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        contentset,
        locale,
        searchKey,
        filterValues,
      }),
    });

    data = await response.json();

    if (response.status === 500 && "error" in data) {
      // eslint-disable-next-line no-console
      console.error(data.error);
      data = [];
    }
  } catch (error) {
    console.error("Failed to fetch:", error);
  }

  return data as SelectOption[];
}

async function getSearchValueFromFilterQuery(
  filterQuery: FilterQuery,
  contentset: Contentset,
  locale: LocaleCode,
  productTags: Attribute_Option[],
) {
  const searchKeys = Object.keys(filterQuery?.searchValue || {});
  const requestSearchValue = Object.entries(
    filterQuery?.searchValue ?? {},
  ).reduce((requestSearchValue, [searchKey, value]) => {
    if (isEmpty(value)) return requestSearchValue;
    return {
      ...requestSearchValue,
      [searchKey]: {
        value,
      },
    };
  }, {});
  let newSearchValue = {};
  await Promise.all(
    searchKeys.map(async (searchKey) => {
      const options: SelectOption[] | { error: string } =
        await getSearchOptions(contentset, locale, searchKey, {
          searchValue: requestSearchValue,
          productTags,
        });

      newSearchValue = { ...newSearchValue, [searchKey]: options[0] };
    }),
  );
  return newSearchValue;
}

function getFilterQuerySearchValue(
  searchValue: ContentsetContextProps["searchValue"],
) {
  return Object.entries(searchValue).reduce(
    (filterQuerySearchValue, [searchKey, value]) => {
      return {
        ...filterQuerySearchValue,
        [searchKey]: value?.value,
      };
    },
    {},
  );
}

export const ContentSet: FC<ContentSetProps> = ({
  contentset,
  items,
  filters: initialFilters,
  pagination,
  skeleton = false,
  base,
  initialSearchValue = {},
  productTags,
  headline = "",
  attributes,
}) => {
  const { language, locale } = useLocale();
  const path = useSlugPath();

  const [filters, setFilters] = useState(initialFilters);

  const columns = useMemo(
    () =>
      getRawColumns(contentset).map(
        (column) => omit(column, ["meta"]) as ColumnDef<ContentsetEntity>,
      ),
    [contentset],
  );

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  const [activeView, setActiveView] = useQueryState<ViewString>(
    `${contentset.id}-view`,
    contentset.layout === "dlc"
      ? "list_thin"
      : isMobile
      ? contentset.entity_type === "ws"
        ? "list_thin"
        : "list"
      : (contentset?.default_view as ViewString),
  );

  const [view, setView] = useState<ViewType>({
    active: activeView,
    map: viewMap,
  });

  const [columnVisibility, setColumnVisibility] = useState(
    getColumnVisibility(contentset, view.active),
  );

  useEffect(() => {
    setActiveView(view.active);
    setColumnVisibility(getColumnVisibility(contentset, view.active));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const [data, setData] = useState<ContentsetEntity[]>(items);

  const tableInstance = useReactTable({
    data: data,
    columns,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  // Handle state of modal
  const [modalState, setModalState] = useState<boolean>(false);

  // Filter query
  const [filterQuery, setFilterQuery] = useQueryState<FilterQuery>(
    `${contentset.id}-filter`,
    {
      searchValue: getFilterQuerySearchValue(initialSearchValue),
    },
  );

  // Handle select values
  const [searchValue, setSearchValue] =
    useState<ContentsetContextProps["searchValue"]>(initialSearchValue);
  const [searchInput, setSearchInput] = useState<
    ContentsetContextProps["searchInput"]
  >(filterQuery?.searchInput ?? {});

  useEffect(() => {
    if (
      (isEmpty(filterQuery?.searchValue) ||
        isEqual(searchValue, getFilterQuerySearchValue(initialSearchValue))) &&
      isEmpty(productTags)
    ) {
      return;
    }

    (async () => {
      const newSearchValue = await getSearchValueFromFilterQuery(
        filterQuery,
        contentset,
        locale,
        productTags,
      );
      setSearchValue(newSearchValue);
    })().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle selected options
  const [selectedOptions, setSelectedOptions] = useState<
    ContentsetContextProps["selectedOptions"]
  >(filterQuery?.selectedOptions ?? {});

  useEffect(() => {
    const filterQuerySearchValue = getFilterQuerySearchValue(searchValue);
    setFilterQuery({
      searchValue: filterQuerySearchValue,
      searchInput,
      selectedOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchInput, selectedOptions]);

  // Keep track of clicked filter
  const [modalTarget, setModalTarget] = useState<string>();

  // Handle increase size
  const [increaseSize] = useState(+contentset.display_limit_increase || 16);

  const [nextPage, setNextPage] = useQueryState(
    `${contentset.id}-page`,
    pagination.nextPage || 1,
  );

  const [isLoadingContentsetPage, setIsLoadingContentsetPage] = useState(false);

  const [dataInfo, setDataInfo] = useState({
    itemsTotal: pagination.itemsTotal,
    itemsLeft: pagination.itemsLeft,
    percentage: 100 - (pagination.itemsLeft / pagination.itemsTotal) * 100 || 0,
  });

  // Controller to allow cancelling of fetch
  const controller = useRef<AbortController>(null);

  // Ref to contentset wrapper
  const contentsetWrapperRef = useRef<HTMLDivElement | null>(null);

  // State of last search to remove redundant searches
  const [lastPageFetchBody, setLastPageFetchBody] = useState<string>();

  /**
   * Function to fetch new data
   *
   * @param pageIndex index of the page until which items should be fetched
   * @param replace if true items from beginning to page will be fetched, else only items after offset of current data will be fetched (next items/page)
   * @returns contentset page with items as entities and meta information
   */
  const fetchNewPage = async (
    pageIndex?: number,
    replace?: boolean,
    paginate?: boolean,
  ): Promise<ContentsetPage | null> => {
    try {
      const body = JSON.stringify({
        pageIndex: typeof pageIndex === "number" ? pageIndex : nextPage,
        offset: pageIndex === 0 || replace ? 0 : data.length,
        contentset,
        locale,
        filterValues: {
          selectedOptions,
          searchValue,
          searchInput,
          ...(!isEmpty(productTags) && { productTags }),
        },
        paginate: typeof paginate === "boolean" ? paginate : true,
      });

      // If the last request was the same prevent a new one
      if (body === lastPageFetchBody) return;

      if (pageIndex === 0 || replace) {
        setData([]);
      }

      setLastPageFetchBody(body);
      setIsLoadingContentsetPage(true);

      // Cancel previous request
      if (controller.current) {
        controller.current.abort();
      }
      controller.current = new AbortController();

      const response = await fetch(`/api/contentset`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
        signal: controller.current.signal,
      });

      const json: ContentsetPageWithError = await response.json();

      if ("error" in json) {
        return null;
      }
      setIsLoadingContentsetPage(false);
      return json as ContentsetPage;
    } catch (e) {
      return null;
    }
  };

  /**
   * Provide function to handle loading more entities
   *
   * @param pageIndex index of the page until which items should be rendered, zero-based
   * @param replace if true the items from beginning to page will be fetched and replaced, else the current data remains and new items will just be added
   * @param paginate if false the items will be fetched without pagination
   */
  const filterAndLoadMore = async (
    pageIndex?: number,
    replace?: boolean,
    paginate?: boolean,
  ) => {
    const fetchedPage = await fetchNewPage(pageIndex, replace, paginate);
    if (fetchedPage) {
      if (pageIndex === 0 || replace) setData(fetchedPage.entities);
      else setData([...data, ...fetchedPage.entities]);
      setDataInfo({
        itemsTotal: fetchedPage.itemsTotal,
        itemsLeft: fetchedPage.itemsLeft,
        percentage:
          100 - (fetchedPage.itemsLeft / fetchedPage.itemsTotal) * 100 || 0,
      });
      setFilters(fetchedPage.filters);
      setNextPage(fetchedPage.nextPage);
    }
  };

  const clearFilters = () => {
    setSelectedOptions({});
    setSearchValue(initialSearchValue);
    setSearchInput({});
    const filterQuerySearchValue = Object.entries(initialSearchValue).reduce(
      (filterQuerySearchValue, [searchKey, value]) => {
        return {
          ...filterQuerySearchValue,
          [searchKey]: value?.value,
        };
      },
      {},
    );
    setFilterQuery({
      searchInput: {},
      searchValue: filterQuerySearchValue,
      selectedOptions: {},
    });
  };

  // Handle loading new data when filter variables change
  useDidMountEffect(() => {
    void filterAndLoadMore(0);
  }, [searchInput, searchValue, selectedOptions]);
  // Handle loading new data when filter are set initially
  useEffect(() => {
    if (
      [searchInput, searchValue, selectedOptions].some(
        (filter) => !!Object.values(filter || {}).length,
      )
    ) {
      void filterAndLoadMore(Math.max(nextPage - 1, 0), true);
    } else if (nextPage > 1) {
      void filterAndLoadMore(nextPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generatePaginationUrl = (page: number) => {
    const url = generateUrl(locale, path.replace(/^\//, ""));
    const query = buildQueryUrl(
      `${contentset.id}-page`,
      page,
      1,
      path,
    )[0].split("?")[1];

    if (!query || page === 1) {
      return url;
    }

    return `${url}?${query}`;
  };

  return (
    <ContentsetContextProvider
      tableInstance={tableInstance}
      contentset={contentset}
      attributes={attributes}
      filters={filters}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      increaseSize={increaseSize}
      filterAndLoadMore={filterAndLoadMore}
      modalState={modalState}
      setModalState={setModalState}
      view={view}
      setView={setView}
      viewMap={viewMap}
      setColumnVisibility={setColumnVisibility}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      setNextPage={setNextPage}
      dataInfo={dataInfo}
      isLoadingContentsetPage={isLoadingContentsetPage}
      nextPage={nextPage}
      modalTarget={modalTarget}
      setModalTarget={setModalTarget}
      skeleton={skeleton}
      base={base}
      language={language}
      clearFilters={clearFilters}
      contentsetWrapperRef={contentsetWrapperRef}
    >
      <Head>
        {nextPage > 1 && (
          <>
            <meta name="robots" content="noindex, follow" />
            <link rel="prev" href={generatePaginationUrl(nextPage - 1)} />
          </>
        )}
        {/* if has next page */}
        {pagination.itemsLeft > 0 && (
          <link rel="next" href={generatePaginationUrl(nextPage + 1)} />
        )}
      </Head>
      <div
        className={classNames(
          "contentset-wrapper mt-2",
          contentset.show_preview_filter &&
            "contentset-wrapper--preview-filter",
        )}
        ref={contentsetWrapperRef}
      >
        <ContentSetInterior headline={headline} />
      </div>
    </ContentsetContextProvider>
  );
};
